.calculation{
    
        background-image: url(../../../public/images/calculator.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 700px;
        width: 100%;
        background-blend-mode: color;
        background-color: #00000085;
        margin-top: 80px;
     
    
}
fieldset:first-child{    box-shadow: 20px 20px #000000}
fieldset {
    border: .07rem solid #eee;
    border-radius: .3rem;
    padding: 2rem;
    min-width: inherit;
    margin: 80px 0px 30px;
    background:floralwhite;
}

#ntum,.nlb {
    font-size: .4rem
}

fieldset>ol {
    list-style-type: none;
    margin: 30px 0px;
}

fieldset>ol>li {
    padding: .7rem .2rem;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

fieldset>ol>li>label {
    font-weight: 700;
    min-width: 4.3rem;
    white-space: nowrap
}

fieldset>ol>li>label em{color: red;}

ol.narrowest>li>label {
    min-width: 3rem;
    width: 10rem;
    font-size: 21px;
    padding-right: 20px;
    text-align: left;
}

ol.narrower>li>label {
    min-width: 3.5rem
}

ol.narrow>li>label {
    min-width: 4rem
}


fieldset>ol>li>fieldset {
    margin: 0 5px 0 0;
    padding: 0;
    border: 0;
    font-size: 19px;
}

fieldset>ol>li>fieldset>label {
    padding: .2rem 0;
    display: block;
    font-size: 19px;
}

.illabel>label,.illabel>label:first-child {
    display: inline-block;
    padding: 0
}

fieldset>ol>li>fieldset>label:first-child {
    padding-top: .1rem
}

input[type=email],input[type=number],input[type=text],select,textarea {
    font-size: 1rem;
    padding: .3rem .6rem;
    box-shadow: .03rem .1rem .15rem 0 rgba(0,0,0,.08);
    border: 1px solid #aaa;
    margin: 0 5px
}

textarea {
    width: 100%;
    height: 200px
}

input[type=radio] {
    width: 1rem;
    height: 1rem
}

li>label {
    cursor: auto
}

input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input[type=number] {
    -moz-appearance: textfield
}

button[type=reset],button[type=submit] {
    position: relative;
    overflow: hidden;
    transition: .5s;
    cursor: pointer;
    border: 0;
    border-radius: .1rem;
    padding: .4rem .8rem;
    font-size: 1.2rem;
    font-weight: 700;
    box-shadow: .05rem .05rem .2rem #ccc;
    margin-right: 15px;
}

button[type=submit] {
    background-color: #3b8cee;
    color: #fff
}

button[type=reset] {
    color: #000;
    background: lightgray;
}

button[type=reset]:active,button[type=submit]:active {
    box-shadow: .03rem .03rem .03rem #ccc
}

button[type=reset]:after,button[type=submit]:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255,255,255,.5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1,1) translate(-50%);
    transform-origin: 50% 50%
}

@keyframes ripple {
    0% {
        transform: scale(0,0);
        opacity: 1
    }

    20% {
        transform: scale(25,25);
        opacity: 1
    }

    100% {
        opacity: 0;
        transform: scale(40,40)
    }
}

button[type=reset]:focus:not(:active)::after,button[type=submit]:focus:not(:active)::after {
    animation: 1s ease-out ripple
}

.result{padding-top: 30px;
    font-size: 18px;}
    .result span{color: red;
        font-weight: bolder;
        font-size: 20px;}


@media (max-width:767px) {
    input[type=email],input[type=number],input[type=text],select,textarea {
     width: 120px;  
    }
    
fieldset>ol>li>fieldset{font-size: 15px;}
.result{font-size: 15px;}
.calculation{height: 100%;}
}