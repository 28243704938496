
.dicat .title{padding: 100px 0 40px;}
.dicat #cmsmasters_row_66575934dcee86_49225579 {
    background-image: url(https://healthy-living.cmsmasters.net/wp-content/uploads/2015/11/baba-i-flag.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    position: static;
}
.dicat #cmsmasters_row_66575934dcee86_49225579 .cmsmasters_row_outer_parent {
    padding-bottom: 200px;
}
.dicat #cmsmasters_row_66575934dcee86_49225579 .cmsmasters_row_outer_parent {
    padding-top: 95px;
}
.dicat .cmsmasters_row .cmsmasters_row_outer_parent {
    padding: .1px 0;
    z-index: 2;
}
.dicat .cmsmasters_row_outer_parent {
    width: 100%;
    position: relative;
}
.dicat .cmsmasters_row_outer_parent {
    width: 100%;
    position: relative;
}

 
.dicat .cmsmasters_color_scheme_third {
    color: #878787;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
}

.dicat .cards {
  display: grid;
  grid-template-columns:repeat(auto-fit,minmax(300px,1fr));
  list-style: none;
  margin: 0;
  padding: 0;
}
.dicat .card_text{overflow-y: scroll;
    height: 200px;
    line-height: 19px;
}
.dicat .card_text::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.dicat .card_text::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.dicat .card_text::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148)));
}


.dicat .cards_item {
  display: flex;
  padding: 1rem;
}

.dicat .card_image {
  height: calc(10*1.2rem);
  padding: 1.2rem 1.2rem 0;
  position:relative;
}
.dicat .card_image:before,
.dicat .card_image:after{
  content: "";
  position: absolute;
  width: 20px;
  left: 60%;
  top: 0;
  height: 45px;
  background: #e6e6e6b8;
  transform: rotate(45deg);
}
.dicat .card_image:after{
  transform: rotate(-45deg);
  top:auto;
  bottom:-22px;
  left:40%;
}
.dicat .card_image img {
  width:100%;
  height:100%;
  object-fit:cover;
}

.dicat .cards_item {
  filter:drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
}


.dicat .card {
  background-color: white;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 30px;
  background: repeating-linear-gradient(#0000 0 calc(1.2rem - 1px),#66afe1 0 1.2rem) right bottom /100% 100%,linear-gradient(red 0 0) 30px 0/2px 100% #fff;
  background-repeat: no-repeat;
  line-height: 1.2rem;
  -webkit-mask:radial-gradient(circle .8rem at 2px 50%,#0000 98%,#000)0 0/100% 2.4rem;
}

.card_content {
  padding: 1.2rem;
}

.dicat h2.card_title,p {
  margin: 1.2rem 0;
}
.dicat h2.card_title {
    font-size: 1.1em;
}
.dicat body {
  font-family:monospace;
  background:#eee;
}

