
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,500;0,600;1,400&display=swap');

body{font-family: 'Josefin Sans', sans-serif;}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



