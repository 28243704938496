.gwendolyn-regular {
    font-family: "Gwendolyn", cursive;
    font-weight: 400;
    font-style: normal;
  }
  
  .gwendolyn-bold {
    font-family: "Gwendolyn", cursive;
    font-weight: 700;
    font-style: normal;
  }
  
footer * {
    margin: 0;
    padding: 0;
  }
  footer a {
    color: #fff;
    text-decoration: none;
  }
  .pg-footer {
    font-family: 'Roboto', sans-serif;
    margin-top: 100px;
  }
  
  
  .footer {
      background-color: #004658;
      color: #fff;
  }
  .footer-wave-svg {
      background-color: transparent;
      display: block;
      height: 30px;
      position: relative;
      top: -1px;
      width: 100%;
  }
  .footer-wave-path {
      fill: #ffffff;
  }
  
  .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 200px;
      position: relative;
  }
  
  .footer-content-column {
      box-sizing: border-box;
      float: left;
      padding-left: 15px;
      padding-right: 15px;

      color: #fff;
  }
  
  .footer-content-column ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-logo-link {height: 70px;display: flex;}
  .footer-logo .title {
      display: inline-block;
      font-family: "Gwendolyn", cursive;
      font-size: 45px;

  }
  .footer-logo p{text-align: justify;
    line-height: 2;
    font-family: cursive;}
  .footer-menu {
      margin-top: 30px;
  }
  
  .footer-menu-name {
      color: #fffff2;
      font-size: 15px;
      font-weight: 900;
      letter-spacing: .1em;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 0;
      text-transform: uppercase;
  }
  .footer-menu-list {
      list-style: none;
      margin-bottom: 0;
      margin-top: 10px;
      padding-left: 0;
  }
  .footer-menu-list li {
      margin-top: 5px;
  }
  
  .footer-call-to-action-description {
      color: #fffff2;
      margin-top: 10px;
      margin-bottom: 20px;
  }
  .footer-call-to-action-button:hover {
      background-color: #fffff2;
      color: #00bef0;
  }
  .button:last-of-type {
      margin-right: 0;
  }
  .footer-call-to-action-button {
      background-color: #027b9a;
      border-radius: 21px;
      color: #fffff2;
      display: inline-block;
      font-size: 20px;
      font-weight: 900;
      letter-spacing: .1em;
      line-height: 18px;
      padding: 12px 30px;
      margin: 10px 10px 10px 0;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color .2s;
      cursor: pointer;
      position: relative;
  }
  .footer-call-to-action {
      margin-top: 30px;
  }
  .footer-call-to-action-title {
      color: #fffff2;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: .1em;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 0;
      text-transform: uppercase;
  }
  .footer-call-to-action-link-wrapper {
      margin-bottom: 0;
      margin-top: 10px;
      color: #fff;
      text-decoration: none;
  }
  .footer-call-to-action-link-wrapper a {
      color: #fff;
      text-decoration: none;
  }
  
  
  
  
  
  .footer-social-links {
      bottom: 0;
      height: 54px;
      position: absolute;
      right: 0;
      width: 236px;
  }
  
  .footer-social-amoeba-svg {
      height: 55px;
      left: 0;
      display: block;
      position: absolute;
      top: 0;
      width: 236px;
  }
  
  .footer-social-amoeba-path {
      fill: #027b9a;
  }
  
  .footer-social-link.phone {
      height: 26px;
      left: 3px;
      top: 11px;
      width: 45px;
  }
  .footer-social-link.phone i{font-size: 19px;}
  
  .footer-social-link {
      display: block;
      padding: 10px;
      position: absolute;
  }
  
  .hidden-link-text {
      position: absolute;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px,1px,1px,1px);
      -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
      clip-path: inset(0px 0px 99.9% 99.9%);
      overflow: hidden;
      height: 1px;
      width: 1px;
      padding: 0;
      border: 0;
      top: 50%;
  }
  
  .footer-social-icon-svg {
      display: block;
  }
  
  .footer-social-icon-path {
      fill: #fffff2;
      transition: fill .2s;
  }
  
  .footer-social-link.twitter {
      height: 28px;
      left: 62px;
      top: 3px;
      width: 45px;
  }
  
  .footer-social-link.instagram {
      height: 24px;
      left: 123px;
      top: 12px;
      width: 45px;
  }
  .footer-social-link.instagram i{font-size: 25px;}
  .footer-social-link.whatsapp,.footer-social-link.whatsapp {
      height: 34px;
      left: 172px;
      top: 7px;
      width: 45px;
  }
  .footer-social-link.whatsapp i{    font-size: 28px;padding: 3px;}
  i.fa-s{font-size: 22px; padding: 6px;}
  i.fa-s:hover{ background: #6700ff; color: #14f7c4;  border-radius: 7px;}
  
  .footer-copyright {
      background-color: #027b9a;
      color: #fff;
      padding: 15px 30px;
    text-align: center;
  }
  
  .footer-copyright-wrapper {
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
  }
  
  .footer-copyright-text {
    color: #fff;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 0;
  }
  
  .footer-copyright-link {
      color: #fff;
      text-decoration: none;
  }
  
  
  
  
  
  
  
  /* Media Query For different screens */
  @media (max-width:760px) {
    .footer-logo p{text-align: center;} 
    .footer-logo-link {justify-content: center;}
    footer form label{width: 100%;}
    footer form label{padding-bottom: 4px;}
    footer form .field{padding-bottom: 10px;}
    footer form .field input{width: 100%;}
  }
  @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 200px;
      position: relative;
    }
  }
  @media (min-width:480px) and (max-width:599px)  { /* smartphones, Android phones, landscape iPhone */
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
    }
    
  }
  @media (min-width:600px) and (max-width: 800px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
    }
  }
  @media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
  
  }
  @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
  
  }
  @media (min-width:1281px) { /* hi-res laptops and desktops */
  
  }
   
  @media (min-width: 760px) {
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 170px;
        position: relative;
    }
  
    .footer-wave-svg {
        height: 50px;
    }
  
  }

  
  
footer form input{ width: 100%; padding: 6px;margin: 5px 0px;}
  
footer form textarea {
    resize: vertical;
    width: 100%;
    margin: 5px 0px !important;
    padding: 5px !important;
}
footer form label{padding: 10px 0px 0px 0px;}
footer form i {    padding-right: 10px;}
/*footer .footer-content-column .container {    box-shadow: rgb(0 0 0) 0px 20px 30px -10px;} */

.scroll-top div {  width: 50px;  height: 50px; margin: 0 5px; display: flex;  justify-content: center;  align-items: center;  background-color: #ffc107;  border-radius: 50%;} 
.scroll-top {  position: fixed;  bottom: 10px; right: 30px;display: flex;z-index: 9; padding: 1px 0px;}





 

  
